export const spaces = {
  k: ["50px", "100px"],
  h: ["38px", "64px"],
  l: ["24px", "32px"],
  m: ["12px", "16px"],
  s: ["6px", "8px"],
  x: ["3px", "4px"],
  n: ["0px", "0px"]
};

export const space = spaces;
