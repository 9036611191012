export const tracking = {
  h: ".64em",
  l: ".32em",
  m: ".16em",
  s: ".08em",
  x: ".04em",
  n: "0"
};

export const track = tracking;
