export const fsizes = {
  k: ["28px", "56px"],
  h: ["24px", "42px"],
  l: ["18px", "24px"],
  m: ["16px", "18px"],
  s: ["14px", "16px"],
  x: ["13px", "14px"]
};

export const fsize = fsizes;
